import axios, { type AxiosRequestConfig } from 'axios';
// @ts-ignore
import { clearToken, getToken } from '@/utils/auth';
import { getItem, setItem, removeItem, userConstant } from '@/utils/storage';
const { USER_EMAIL, USER_NAME, USER_TOKEN, USER_AVATAR } = userConstant;
import router from '../router/router';

// Credit不足的code码
const noCredit = [20001, 30001, 40001, 70001, 80001, 90001];
export interface ResponseType<T> {
  code: number;
  data: T;
  msg: string;
  noCredit: boolean;
  success: boolean;
}

// 创建axios实例
const httpService = axios.create({
  timeout: 1500000, // 请求超时时间
});

// request拦截器
httpService.interceptors.request.use(
  (config) => {
    const currentDomain = getItem('currentDomain');
    // 让每个请求携带token
    config.headers['Content-Type'] = 'application/json';
    config.headers.Authorization = getToken();

    if (currentDomain && currentDomain == 2) {
      config.headers['x-app-id'] = 4;
    }
    return config;
  },
  (error) => {
    // Do something with request error
    Promise.reject(error);
  }
);

// response拦截器
httpService.interceptors.response.use(
  ({ data }) => {
    const result = {
      success: true,
      noCredit: false,
      ...data,
    };

    // 鉴权失败，跳转登录页面
    if (data.code === 101 || data.code === 401 || data.code === 403) {
      clearToken();
      result.success = false;
      removeItem(USER_TOKEN);
      removeItem(USER_NAME);
      removeItem(USER_EMAIL);
      removeItem(USER_AVATAR);
      if (router.currentRoute.value.meta.isAuth) {
        router.push('/workspace');
      }
    } else if (noCredit.includes(data.code)) {
      result.success = false;
      result.noCredit = true;
    } else if (data.code !== 0) {
      result.success = false;
      result.message = data.msg;
    }
    return result;
  },
  (error) => {
    console.log(error);
    if (error.response) {
      switch (error.response.status) {
        case 400:
          error.message = 'Request error';
          break;
        case 401:
          error.message = 'Unauthorized, please log in again';
          break;
        case 403:
          error.message = 'No access';
          break;
        case 404:
          error.message = 'Request error, the resource was not found';
          break;
        case 405:
          error.message = 'Request method not allowed';
          break;
        case 408:
          error.message = 'Request timeout';
          break;
        case 500:
          error.message = 'Server side error';
          break;
        case 501:
          error.message = 'Network not implemented';
          break;
        case 502:
          error.message = 'Network error';
          break;
        case 503:
          error.message = 'The service is unavailable';
          break;
        case 504:
          error.message = 'Network Timeout';
          break;
        case 505:
          error.message = 'The HTTP version does not support this request';
          break;
        default:
          error.message = `Unknown error, code: ${error.response.status}`;
      }
    } else {
      error.message = 'Connection to server failed';
    }
    const result = {
      success: false,
      code: error.response?.data?.code || -1,
      msg: error.message,
    };
    return result;
  }
);

/*
 *  get请求
 *  url:请求地址
 *  params:参数
 * */
export function _get<T>(
  url: string,
  params: object = {},
  config: AxiosRequestConfig = {}
) {
  type ResponseData = ResponseType<T>;

  return new Promise<ResponseData>((resolve, reject) => {
    httpService<any, ResponseData>({
      url,
      method: 'get',
      params,
      ...config,
    })
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export function _singleGet<T>(url: string, data?: string | number) {
  type ResponseData = ResponseType<T>;
  let requestUrl = url;

  if (data) {
    requestUrl = `${url}/${data}`;
  }
  return new Promise<ResponseData>((resolve, reject) => {
    httpService<any, ResponseData>({
      url: requestUrl,
      method: 'get',
    })
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

/*
 *  post请求
 *  url:请求地址
 *  params:参数
 * */
export function _post<T>(
  url: string,
  params: object = {},
  config: AxiosRequestConfig = {}
) {
  type ResponseData = ResponseType<T>;

  return new Promise<ResponseData>((resolve, reject) => {
    httpService<any, ResponseData>({
      url,
      method: 'post',
      data: params,
      ...config,
    })
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

/*
 *  put请求
 *  url:请求地址
 *  params:参数
 * */
export function _put<T>(url: string, data: any = {}, params?: any) {
  type ResponseData = ResponseType<T>;

  return new Promise<ResponseData>((resolve, reject) => {
    httpService<any, ResponseData>({
      url,
      method: 'put',
      data,
      params,
    })
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

/*
 *  delete请求
 *  url:请求地址
 *  params:参数
 * */
export function _delete<T>(
  url: string,
  params?: any,
  config: AxiosRequestConfig = {}
) {
  type ResponseData = ResponseType<T>;

  return new Promise<ResponseData>((resolve, reject) => {
    httpService<any, ResponseData>({
      url,
      params,
      method: 'delete',
      ...config,
    })
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export function commonUpload<T>(url: string, params: Record<string, any> = {}) {
  const formData = new FormData();
  type ResponseData = ResponseType<T>;

  for (const key of Object.keys(params)) {
    formData.append(key, params[key]);
  }

  return new Promise<ResponseData>((resolve, reject) => {
    httpService<any, ResponseData>({
      url,
      method: 'post',
      data: formData,
    })
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export function _getFileDownload<T>(url: string, params: string | number) {
  type ResponseData = ResponseType<T>;

  return new Promise<ResponseData>((resolve, reject) => {
    const requestUrl = `${url}/${params}`;

    axios<any, ResponseData>({
      url: requestUrl,
      method: 'get',
      responseType: 'blob',
      headers: {
        Authorization: getToken() || '',
        'Accept-Language': 'en-US',
      },
    })
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export function _postFileDownload<T>(
  url: string,
  params: Record<string, any> = {}
) {
  type ResponseData = ResponseType<T>;

  return new Promise<ResponseData>((resolve, reject) => {
    const requestUrl = url;

    axios<any, ResponseData>({
      url: requestUrl,
      method: 'post',
      data: params,
      responseType: 'blob',
      headers: {
        Authorization: getToken() || '',
        'Accept-Language': 'en-US',
      },
    })
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

/*
 *  url:请求地址
 *  params:参数
 * */
export function awsFileUpload(url: string, file: File, config?: Object) {
  return new Promise<{ code: number }>((resolve, reject) => {
    axios
      .put(url, file, config)
      .then((res) => {
        if (res.status == 200) {
          resolve({ code: 0 });
        } else {
          reject({ code: -1 });
        }
      })
      .catch((err) => {
        reject({ code: -1 });
      });
  });
}
