<script setup>
import { ref, watchEffect, defineProps } from 'vue';
import ForgotFirst from './forgot-first.vue';
import ForgotThree from './forgot-third.vue';
import { useRoute } from 'vue-router';

const props = defineProps({
  resetConfig: Object,
  pageType: String,
});
const route = useRoute();

const tab = {
  ForgotFirst,
  ForgotThree,
};
const currentTab = ref('ForgotFirst');

watchEffect(() => {
  // if (props.pageType == 'modal') {
  //   currentTab.value =
  //     props.resetConfig?.step == 1 ? 'ForgotFirst' : 'ForgotThree';
  //   return;
  // }
  currentTab.value = route.query.step === '1' ? 'ForgotFirst' : 'ForgotThree';
});

document.title = route.meta.title;
</script>

<template>
  <component
    :is="tab[currentTab]"
    class="forgot w-4/12"
    :resetConfig="resetConfig"
    :pageType="pageType"
  />
</template>

<style lang="scss" scoped>
.forgot {
  margin: 0 auto;
  height: 100%;
  display: flex;
  padding-top: 200px;
  align-items: center;
  flex-direction: column;
}
</style>
