<script setup lang="ts">
import { reactive, ref, watch, h, onMounted, defineProps } from 'vue';
import GoogleLogin from './google-login.vue';
import { Loading } from '@element-plus/icons-vue';
import prefix_name from '@/assets/images/login/user-name.svg';
import prefix_email from '@/assets/images/login/prefix_email.svg';
import prefix_password from '@/assets/images/login/prefix_password.svg';
import prefix_name_fail from '@/assets/images/login/fail_username.svg';
import prefix_email_fail from '@/assets/images/login/fail_email.svg';
import prefix_password_fail from '@/assets/images/login/fail_password.svg';
import { useDebounceFn } from '@vueuse/core';
import { checkForm } from '../utils';
import VerificationCode from './verification-code.vue';
import { useIntervalFn } from '@vueuse/core';
import { useUserStore } from '@/store/modules/user';
import { PrimaryButton } from '@/components/common';
import { useRouter, useRoute } from 'vue-router';
import precisoTrackEvent from '@/utils/precisoTrackEvent.ts';
import smPrecisoTrackEvent from '@/utils/smPrecisoTrackEvent.ts';

const props = defineProps({
  pageType: String,
});
const emit = defineEmits(['changeTab']);

const globalDomain = inject('globalDomain');
const router = useRouter();
const route = useRoute();
const user = useUserStore();

const formState = reactive({
  name: '',
  email: '',
  password: '',
});
const registerButtonDisabled = ref(true);

onMounted(() => {
  if (globalDomain == 1 && import.meta.env.VITE_ENV == 'prod') {
    precisoTrackEvent({
      Currency: ' ',
      CustomerType: 'Free',
      ProductId: 3,
      CouponCode: ' ',
      OrderId: ' ',
      Amount: '0',
      PageType: 'checkout',
    });

    return;
  }

  if (globalDomain == 2 && import.meta.env.VITE_ENV == 'prod') {
    smPrecisoTrackEvent({
      PageType: 'basket',
    });
    return;
  }
});

watch(formState, () => {
  /* 深层级变更状态所触发的回调 */
  if (formState.email && formState.name && formState.password) {
    registerButtonDisabled.value = false;
  } else {
    registerButtonDisabled.value = true;
  }
});

const isLoading = ref(false);
const errorState = reactive({
  name: 'success',
  email: 'success',
  password: 'success',
  errorMsg: '',
});

const step = ref(1);

//init errorState
const initErrorState = () => {
  errorState.name = 'success';
  errorState.email = 'success';
  errorState.password = 'success';
  errorState.errorMsg = '';
};

const setErrorState = (msg: string) => {
  errorState.name = 'error';
  errorState.email = 'error';
  errorState.password = 'error';
  errorState.errorMsg = msg;
};

const indicator = h(Loading, {
  style: {
    fontSize: '20px',
    color: '#fff',
    marginRight: '8px',
  },
  spin: true,
});

const handleInput = (name: string) => {
  errorState[name] = 'success';
  errorState.errorMsg = '';
};

const timer = ref(0);

const { pause, resume } = useIntervalFn(
  () => {
    if (timer.value <= 0) {
      // 停止定时任务
      pause();
    } else {
      // 单次定时任务执行的回调
      timer.value--;
    }
  },
  1000,
  {
    // 默认不开启定时任务
    immediate: false,
  }
);

const sendEmail = async () => {
  if (timer.value === 0) {
    isLoading.value = true;
    const res: any = await user.sendEmail({ email: formState.email });
    isLoading.value = false;
    const { code, msg } = res;
    if (code !== 0) {
      errorState.errorMsg = msg;
    } else {
      timer.value = 60;
      resume();
      step.value = 2;
    }
  } else {
    step.value = 2;
    return;
  }
};

const debouncedFn = useDebounceFn((value) => {
  //send code
  sendEmail();
}, 500);

const onFinish = async (values: Object) => {
  isLoading.value = true;
  await checkForm(values, false)
    .then(() => {
      initErrorState();
      debouncedFn(values);
    })
    .catch((errorInfo) => {
      errorState.errorMsg = errorInfo.message;
      errorState.name = errorInfo.errorFiles.includes('name')
        ? 'error'
        : 'success';
      errorState.email = errorInfo.errorFiles.includes('email')
        ? 'error'
        : 'success';
      errorState.password = errorInfo.errorFiles.includes('password')
        ? 'error'
        : 'success';
    });
  isLoading.value = false;
};

const onFinishFailed = (errorInfo: any) => {
  console.log('Failed:', errorInfo);
};

const handleChangeTab = () => {
  if (props.pageType == 'modal') {
    emit('changeTab', 'login');
    return;
  }

  const query = route.query;
  router.push({ path: '/workspace', query });
};
</script>

<template>
  <div>
    <el-form
      v-if="step === 1"
      :model="formState"
      class="login-form w-5/12 flex-1 flex justify-center items-center flex-col"
      name="basic"
      autocomplete="off"
      @finish="onFinish"
      @finishFailed="onFinishFailed"
    >
      <el-form-item class="mb-3">
        <h1 class="text-black text-2xl font-medium">Sign up</h1>
      </el-form-item>
      <el-form-item class="mb-4">
        <span class="text-gray-400 font-normal"
          >Already have an account?
          <a
            @click="handleChangeTab"
            class="cursor-pointer text-[#875eff] hover:text-[#a987ff] duration-300 transition-all"
          >
            Log in</a
          ></span
        >
      </el-form-item>
      <el-form-item class="mb-6">
        <GoogleLogin :pageType="pageType"/>
      </el-form-item>
      <el-form-item name="name" class="mb-7" :validate-status="errorState.name">
        <el-input
          @keyup.enter="onFinish(formState)"
          v-model="formState.name"
          @input="handleInput('name')"
          class="form-input"
          placeholder="Name"
        >
          <template #prefix>
            <img :src="prefix_name" />
          </template>
        </el-input>
      </el-form-item>
      <el-form-item
        name="email"
        class="mb-7"
        :validate-status="errorState.email"
      >
        <el-input
          @keyup.enter="onFinish(formState)"
          v-model="formState.email"
          @input="handleInput('email')"
          class="form-input"
          placeholder="Email"
        >
          <template #prefix>
            <img :src="prefix_email" />
          </template>
        </el-input>
      </el-form-item>
      <el-form-item
        name="password"
        class="mb-3"
        :validate-status="errorState.password"
      >
        <el-input
          @keyup.enter="onFinish(formState)"
          type="password"
          v-model="formState.password"
          @input="handleInput('password')"
          class="form-input"
          placeholder="Password"
          :show-password="true"
        >
          <template #prefix>
            <img :src="prefix_password" />
          </template>
        </el-input>
      </el-form-item>
      <!-- 错误信息展示，常驻 -->

      <el-form-item class="mb-3 error-msg-item">
        <div class="error-msg-box">{{ errorState.errorMsg }}</div>
      </el-form-item>

      <el-form-item>
        <div class="continue-button">
          <PrimaryButton
            @click="onFinish(formState)"
            :disabled="registerButtonDisabled"
            :loading="isLoading"
            class="form-button"
          >
            <span class="text-base font-normal"> Continue with email </span>
          </PrimaryButton>
        </div>
      </el-form-item>
    </el-form>
    <VerificationCode
      v-if="step === 2"
      @return-previous="step = 1"
      :count="timer"
      @re-send="sendEmail"
      :formState="formState"
      :pageType="pageType"
    />
    <div class="text-inc-color text-sm">
      Agree to the
      <a href="/terms" class="underline">Terms of Service</a>,
      <a href="/privacy-policy" class="underline">Privacy Policy</a>, and
      <a href="/cookies-policy" class="underline">Cookie Policy</a>.
    </div>
  </div>
</template>

<style lang="scss" scoped>
.login-form {
  min-width: 375px;

  :deep(.el-form-item) {
    min-width: 375px;
  }

  :deep(.el-input) {
    height: 54px;
    border-color: transparent;
    border-radius: 41px;
    padding: 0;
  }

  :deep(.el-input__wrapper) {
    height: 54px;
    border-radius: 41px;
    padding: 1px 11px;
  }

  :deep(.el-form-item__content) {
    display: flex;
    justify-content: center;
    min-height: 32px;
    flex-wrap: nowrap;
  }

  :deep(.el-input__inner) {
    border-color: transparent;
    box-shadow: none;
    text-align: left;
    padding: 0;
    font-size: 14px;
    color: #000;
  }

  :deep(.el-input__prefix-inner > :last-child) {
    margin-right: 12px;
    margin-left: 10px;
  }

  :deep(.el-input__suffix-inner) {
    margin: 0 13px 0 10px;
  }

  :deep(.el-input__wrapper.is-focus) {
    box-shadow: 0 0 0 1px #875eff inset !important;
  }
}

.continue-button {
  width: 100%;

  :deep(.el-button) {
    width: 100%;
    height: 54px;
    border-radius: 36px;
    // background: linear-gradient(289deg, #632cff 8.11%, #8e68ff 99.95%);
  }
}

.error-msg-box {
  font-weight: 400;
  max-width: 375px;
  color: #f54a45;
  text-align: center;
}

.form-button {
  display: flex;
  align-items: center;
  justify-content: center;
}

.text-inc-color {
  color: #8f9591;
  line-height: 32px;
}
</style>
