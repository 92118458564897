const smPrecisoTrackEvent = (data: Object) => {
  window.data_8042 = [];

  const defaultData = {
    gdpr: '${GDPR}',
    gdpr_consent: '${GDPR_CONSENT_874}',
    gdpr_pd: '${GDPR_PD}',
    us_privacy: '${US_PRIVACY}',
    PageType: '',
  };

  const obj = { ...defaultData, ...data };
  window.data_8042.push(obj);

  // 清除缓存, LB8042中会缓存data_8042的数据导致更改不成功
  if (window.LB8042) {
    window.LB8042 = null;
  }

  const scriptFn = () => {
    var scriptTag = document.getElementById('DLFNPM101');
    if (scriptTag) {
      scriptTag.parentNode.removeChild(scriptTag);
    }

    (function (d) {
      var s = d.createElement('script');
      s.async = true;
      s.id = 'DLFNPMMP1';
      s.type = 'text/javascript';
      s.src = 'https://cdn.preciso.net/p0n6t4c/t2g?8042';
      var a = d.getElementsByTagName('script')[0];
      a.parentNode.insertBefore(s, a);
    })(document);
  };

  scriptFn();
};

export default smPrecisoTrackEvent;
