<script setup>
import { ref, onMounted } from "vue";
import forgot_second_icon from "@/assets/images/login/forgot_second_icon.svg";
import { useIntervalFn } from "@vueuse/core";
import { useRouter } from "vue-router";
const router = useRouter();
const handleBackHome = () => {
  router.push("/workspace");
};

const timer = ref(0);

const { pause, resume } = useIntervalFn(
  () => {
    if (timer.value <= 0) {
      pause();
      handleBackHome();
    } else {
      timer.value--;
    }
  },
  1000,
  {
    immediate: false,
  },
);
onMounted(() => {
  if (timer.value === 0) {
    timer.value = 6;
    resume();
  } else {
    return;
  }
});
</script>

<template>
  <div class="forgot-second-box">
    <img :src="forgot_second_icon" />
    <p>Your password has been reset, ready back to landing page.</p>
    <a @click="handleBackHome">Back({{ timer }}S)</a>
  </div>
</template>

<style lang="scss" scoped>
.forgot-second-box {
  background: #d9f5d6;
  border-radius: 4px;
  display: flex;
  padding: 24px;
  max-width: 685px;

  p {
    text-align: left;
    margin-left: 26px;
    line-height: 36px;
  }

  a {
    line-height: 36px;
    color: #875eff;
    text-decoration: underline;
  }
}
</style>
